<template>
  <div class="mod-green_application_information}">
    <div>
      <el-card>
        <div slot="header" class="clearfix">
          <span>绿通影像件</span>
          <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
        </div>
        <div class="uploadImageContainer">
          <!-- <div class="uploadImageContainer_left"> -->
          <div v-if="showInHospitalList">
            <h3>住院单：</h3>
            <div>
              <el-upload
                :action="url"
                list-type="picture-card"
                multiple
                :disabled="disabled"
                :file-list="inHospitalList"
                :before-upload="beforeUploadHandle"
                :on-success="successHandleInsured"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
          </div>
          <div class="inspection_report" v-if="showCheckList">
            <h3>检查单：</h3>
            <div>
              <el-upload
                :action="url"
                list-type="picture-card"
                multiple
                :disabled="disabled"
                :file-list="checkList"
                :before-upload="beforeUploadHandle"
                :on-success="successHandleReport"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
          </div>
          <!-- </div> -->
          <!-- <div class="vertical_border"></div> -->
          <!-- <div class="uploadImageContainer_right"> -->
          <div v-if="showIllnessReportList">
            <h3>重疾诊断报告：</h3>
            <div>
              <el-upload
                :action="url"
                list-type="picture-card"
                multiple
                :disabled="disabled"
                :file-list="IllnessReportList"
                :before-upload="beforeUploadHandle"
                :on-success="successHandleCases"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
          </div>
          <div class="inspection_report" v-if="showCancerReportList">
            <h3>癌症诊断报告：</h3>
            <div>
              <el-upload
                :action="url"
                list-type="picture-card"
                multiple
                :disabled="disabled"
                :file-list="cancerReportList"
                :before-upload="beforeUploadHandle"
                :on-success="successHandleOtherImg"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
          </div>
          <div class="inspection_report" v-if="showReserve">
            <h3>预约信息：</h3>
            <div>
              <el-upload
                :action="url"
                list-type="picture-card"
                multiple
                :disabled="disabled"
                :file-list="ReserveList"
                :before-upload="beforeUploadHandle"
                :on-success="successHandleReserveList"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
          </div>

          <!-- </div> -->
        </div>
        <div class="inspection_report otherImage" v-if="showOtherImg">
          <h3>其他材料：</h3>
          <div>
            <el-upload
              :action="url"
              list-type="picture-card"
              multiple
              :disabled="disabled"
              :file-list="OtherImgList"
              :before-upload="beforeUploadHandle"
              :on-success="successHandleOtherImg2"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: '',
      num: 0,
      inHospitalList: [], // 被保险人身份图片回显列表
      IllnessReportList: [], // 入院通知单和诊断证明
      checkList: [], // 检查报告
      cancerReportList: [], // 被保险人身份图片回显列表
      OtherImgList: [], // 被保险人身份图片回显列表
      ReserveList: [], // 被保险人身份图片回显列表
      dialogVisibleInsured: false, // 大图显示
      dialogInsuredImageUrl: '', // 大图显示URL
      imgDataObj: {},
      dialogImageUrl: '',
      dialogVisible: false,
      status: '',
      disabled: false,
      showInHospitalList: false,
      showCheckList: false,
      showIllnessReportList: false,
      showCancerReportList: false,
      showOtherImg: true,
      showReserve: false,
      rightCode: '',
    };
  },
  components: {},
  created() {
    this.init();
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.url = `${
        window.SITE_CONFIG['apiURL']
      }/sys/oss/upload?token=${sessionStorage.getItem('token')}`;
      this.num = 0;
      this.showInHospitalList = false;
      this.showCheckList = false;
      this.showIllnessReportList = false;
      this.showCancerReportList = false;
      this.showOtherImg = true;
      this.showReserve = false;
    },
    updates() {
      console.log('图片');
      Object.assign(this.$data, this.$options.data());
    },
    // 父组件传参
    greenFun(params) {
      let inHospitalList = [];
      let IllnessReportList = [];
      let cancerReportList = [];
      let OtherImgList = [];
      let checkList = [];
      let ReserveList = [];
      this.status = params.status;
      if (params.status === 2 || params.status === 0) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
      let fileList = params.entityDetails.healthBusinessFileList;

      console.log(fileList, 'fileUrl');
      for (let i in fileList) {
        let imageObj = {
          uid: fileList[i].id,
          url: fileList[i].fileUrl,
          greenAdvanceId: fileList[i].greenAdvanceId,
          fileName: fileList[i].fileName,
          fileType: fileList[i].fileType,
          fileUrl: fileList[i].fileUrl,
          type: fileList[i].type,
        };

        if (fileList[i].fileType == 1) {
          inHospitalList.push(imageObj);
        } else if (fileList[i].fileType == 16) {
          checkList.push(imageObj);
        } else if (fileList[i].fileType == 3) {
          IllnessReportList.push(imageObj);
        } else if (fileList[i].fileType == 4) {
          cancerReportList.push(imageObj);
        } else if (fileList[i].fileType == 18) {
          OtherImgList.push(imageObj);
        } else if (fileList[i].fileType == 5) {
          ReserveList.push(imageObj);
        }
      }
      this.rightCode = params.rightCode;
      if (
        this.rightCode == 'GREEN_HOSPITAL' ||
        this.rightCode == 'SERIOUS_DISEASE_SURGERY' ||
        this.rightCode == 'CANCER_SURGERY' ||
        this.rightCode == 'GREEN_SURGERY' ||
        this.rightCode == 'GREEN_HOSPITAL_SURGERY'
      ) {
        this.showInHospitalList = true;
      } else if (this.rightCode == 'GREEN_CHECK') {
        this.showCheckList = true;
      } else if (this.rightCode == 'ACCOMPANY_IN_HOSPITAL') {
        this.showReserve = true;
      } else if (this.rightCode == 'GREEN_OUTPATIENT_CANCER') {
        this.showCancerReportList = true;
      } else if (this.rightCode == 'GREEN_OUTPATIENT_SERIOUS_DISEASE') {
        this.showIllnessReportList = true;
      }
      if (
        this.rightCode == 'GREEN_OUTPATIENT' ||
        this.rightCode == 'NO_WAITING_DIAGNOSIS'
      ) {
        this.showOtherImg = false;
      }
      if (
        this.rightCode == 'GREEN_SURGERY'
        // this.showOtherImg = false
      ) {
        this.showCheckList = false;
        this.showIllnessReportList = false;
        this.showCancerReportList = false;
      }
      if (
        this.rightCode == 'ACCOMPANY_IN_HOSPITAL_OBSTETRICS' ||
        this.rightCode == 'GREEN_CHAPERONAGE'
      ) {
        this.showReserve = true;
      }
      this.inHospitalList = inHospitalList;
      this.IllnessReportList = IllnessReportList;
      this.cancerReportList = cancerReportList;
      this.OtherImgList = OtherImgList;
      this.checkList = checkList;
      this.ReserveList = ReserveList;
    },

    // 上传之前
    beforeUploadHandle(file) {
      var FileExt = file.name.replace(/.+\./, '');
      if (
        ['jpg', 'jpeg', 'png', 'gif', 'pdf'].indexOf(FileExt.toLowerCase()) ===
        -1
      ) {
        this.$message({
          type: 'warning',
          // message: '请上传后缀名为jpg、png、txt、pdf、doc、docx、xlsx、zip或rar的附件！'
          message: '请上传后缀名为jpg、png、pdf的附件！',
        });
        return false;
      }
      this.num++;
    },
    // 被保险人身份证明上传成功
    successHandleInsured(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 1;
      data.type = 1;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    // 检查报告：
    successHandleReport(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 16;
      data.type = 1;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    // 入院通知单和诊断证明：
    successHandleCases(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 3;
      data.type = 1;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    // 其他辅助材料
    successHandleOtherImg(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 4;
      data.type = 1;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.fileList = fileList;
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    // 预约信息
    successHandleReserveList(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 5;
      data.type = 1;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.fileList = fileList;
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    successHandleOtherImg2(res, file, fileList) {
      let data = {};
      data.greenAdvanceId = this.$store.state.entityId;
      data.fileName = file.name;
      data.fileUrl = res.data.src;
      data.fileType = 18;
      data.type = 1;
      this.imgDataObj = data;
      if (res.code !== 0) {
        return this.$message.error(res.msg);
      }
      this.fileList = fileList;
      this.num--;
      if (this.num === 0) {
        this.insertGreenFile();
      }
    },
    insertGreenFile() {
      this.$http
        .post(
          `/healthbusiness/health-business-file/insertFile`,
          this.imgDataObj
        )
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          let imageObj = {
            uid: res.data.id,
            url: res.data.fileUrl,
            greenAdvanceId: res.data.greenAdvanceId,
            fileName: res.data.fileName,
            fileType: res.data.fileType,
            fileUrl: res.data.fileUrl,
            type: res.data.type,
          };
          if (res.data.fileType === 1) {
            this.inHospitalList.push(imageObj);
          } else if (res.data.fileType === 16) {
            this.checkList.push(imageObj);
          } else if (res.data.fileType === 3) {
            this.IllnessReportList.push(imageObj);
          } else if (res.data.fileType === 4) {
            this.cancerReportList.push(imageObj);
          } else if (res.data.fileType === 18) {
            this.OtherImgList.push(imageObj);
          } else if (res.data.fileType === 5) {
            this.ReserveList.push(imageObj);
          }
          this.$message({
            message: '上传成功',
            type: 'success',
            duration: 500,
            onClose: () => {},
          });
        })
        .catch(() => {});
    },
    handleRemove(file, fileList) {
      if (this.status === 2) {
        return this.$message.error('服务已完成，无法删除！');
      }
      this.$http
        .get(`/healthbusiness/health-business-file/delete`, {
          params: { id: file.uid },
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          if (file.fileType === 1) {
            this.inHospitalList.splice(
              this.inHospitalList.findIndex((item) => item.id === file.uid),
              1
            );
          } else if (file.fileType === 16) {
            this.checkList.splice(
              this.checkList.findIndex((item) => item.id === file.uid),
              1
            );
          } else if (file.fileType === 3) {
            this.IllnessReportList.splice(
              this.IllnessReportList.findIndex((item) => item.id === file.uid),
              1
            );
          } else if (file.fileType === 4) {
            this.cancerReportList.splice(
              this.cancerReportList.findIndex((item) => item.id === file.uid),
              1
            );
          } else if (file.fileType === 18) {
            this.OtherImgList.splice(
              this.OtherImgList.findIndex((item) => item.id === file.uid),
              1
            );
          }
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 500,
            onClose: () => {},
          });
        })
        .catch(() => {});
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 被保险人身份证明
  },
};
</script>
<style scoped>
.clearfix {
  font-size: 20px;
  font-weight: 700;
}
.el-card {
  border-top: 3px solid rgba(3, 165, 151, 0.616);
}
.el-card /deep/.el-card__body {
  padding-bottom: 0px;
}
.uploadImageContainer {
  display: flex;
  margin-bottom: 20px;
}
.uploadImageContainer_left {
  flex: 50%;
}
.uploadImageContainer_right {
  flex: 50%;
}
.vertical_border {
  border-left: 1px solid #ccc;
}
.inspection_report {
  margin-top: 30px;
}
.uploadImageContainer_right > div {
  margin-left: 30px;
}
h3 {
  color: rgb(114, 113, 113);
}
.otherImage {
  margin-bottom: 20px;
}
</style>
