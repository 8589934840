<template>
  <div class="mod-green_basic_information}">
    <div>
      <!-- <div>用户基本信息</div> -->
      <el-card>
        <div slot="header" class="clearfix">
          <span>绿通执行方案</span>
          <span v-if="showHeaderButton">
            <el-button
              style="float: right; padding: 3px 3px"
              v-if="$hasPermission('miaoins')"
              type="text"
              @click="editorGreenPlain()"
              >编辑</el-button
            >
            <el-button
              style="float: right; padding: 3px 3px"
              v-if="$hasPermission('miaoins')"
              type="text"
              @click="saveGreenPlain()"
              >保存</el-button
            >
          </span>
        </div>
        <div class="basic_information">
          <el-form
            :inline="true"
            :model="greenImplementation"
            :disabled="disabled"
            class="demo-form-inline"
          >
            <!-- <el-form-item label="申请类型">
                    <el-input v-model="greenImplementation.type" placeholder="申请类型"></el-input>
                  </el-form-item> -->
            <el-form-item label="就诊日期">
              <!-- <el-input
                v-model="greenImplementation.greenHospitalDate"
                placeholder="入住日期"
              ></el-input> -->
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="greenImplementation.greenHospitalDate"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="预约医院">
              <el-input
                v-model="greenImplementation.greenHospital"
                placeholder="预约医院"
              ></el-input>
            </el-form-item>
            <el-form-item label="预约科室">
              <el-input
                v-model="greenImplementation.greenDepartment"
                placeholder="预约科室"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="入住病床">
              <el-input
                v-model="greenImplementation.greenBed"
                placeholder="入住病床"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="服务医生">
              <el-input
                v-model="greenImplementation.greenDoctorName"
                placeholder="服务医生"
              ></el-input>
            </el-form-item>
            <el-form-item label="TPA代表">
              <el-input
                v-model="greenImplementation.greenTpa"
                placeholder="TPA代表"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                v-model="greenImplementation.note"
                placeholder="备注"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      header_button: true,
      showHeaderButton: true,
      disabled: true,
      GreenTypeData: [
        { id: 0, name: '男' },
        { id: 1, name: '女' },
      ],
      greenImplementation: {
        id: '',
        type: '',
        greenHospitalDate: '',
        greenHospital: '',
        greenDepartment: '',
        // greenBed: '',
        greenDoctorName: '',
        greenTpa: '',
        note: '',
        ticketId: this.$route.query.id,
      },
      ticketId: '',
    };
  },
  components: {},
  mounted() {},
  methods: {
    // 父组件传参
    greenFuns(params) {
      // console.log('sdafasdasdfasdfasdfasdf',params)
      this.greenImplementation = {
        ...this.greenImplementation,
        ...params.entityDetails,
      };
      this.ticketId = params.id;
      if (params.status === 2 || params.status === 0) {
        this.showHeaderButton = false;
      } else {
        this.showHeaderButton = true;
      }
      this.$store.commit('entityId', params.entityDetails.id);
    },
    // 编辑
    editorGreenPlain() {
      this.header_button = false;
      this.disabled = false;
      this.$emit('fatherMethod');
    },
    // 保存
    saveGreenPlain() {
      this.greenImplementation.ticketId = Number(this.ticketId);
      this.$http
        .post(
          `/healthbusiness/health-green-channel/update`,
          this.greenImplementation
        )
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.$message({
            message: '保存成功',
            type: 'success',
            duration: 500,
            onClose: () => {
              this.header_button = true;
              this.disabled = true;
              // this.getGreenImplementation();
            },
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style scoped>
.clearfix {
  font-size: 20px;
  font-weight: 700;
}
.el-card {
  border-top: 3px solid rgba(3, 165, 151, 0.616);
}
.el-card /deep/.el-card__body {
  padding-bottom: 0px;
}
.basic_information {
  /* width: 90%; */
  /* border: 1px solid #cccccc; */
}
.input {
  display: inline-block;
}
.demo-form-inline {
  margin-left: 20px;
}
.el-form--inline .el-form-item {
  margin-right: 30px;
}
</style>
